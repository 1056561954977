<template>
  <BForm v-on:submit.prevent="onSubmit" novalidate>
    <Alert v-if="!trial" />
    <BFormGroup id="emailInputGroup" label="Email" labelFor="emailInput" label-sr-only>
      <BFormInput
        id="emailInput"
        type="email"
        placeholder="Email"
        v-model="email"
        v-bind:state="$v.email.$dirty ? !$v.email.$error : null"
        aria-describedby="emailInputLiveFeedback"
      >
      </BFormInput>
      <BFormInvalidFeedback id="emailInputLiveFeedback">
        Email is required
      </BFormInvalidFeedback>
    </BFormGroup>
    <BFormGroup id="passwordInputGroup" label="Password" labelFor="passwordInput" label-sr-only>
      <BFormInput
        id="passwordInput"
        type="password"
        placeholder="Password"
        v-model="password"
        v-bind:state="$v.password.$dirty ? !$v.password.$error : null"
        aria-describedby="passwordInputLiveFeedback"
      >
      </BFormInput>
      <BFormInvalidFeedback id="passwordInputLiveFeedback">
        Password is required and must be at least 8 characters
      </BFormInvalidFeedback>
    </BFormGroup>
    <BBtn v-if="trial" type="submit" variant="primary" block v-bind:disabled="loading" class="mb-3">
      <BSpinner label="loading..." v-if="loading" small />
      <span v-else>Start Your Trial</span>
    </BBtn>
    <BBtn v-else type="submit" variant="primary" block v-bind:disabled="loading" class="mb-3">
      <BSpinner label="loading..." v-if="loading" small />
      <span v-else>Register</span>
    </BBtn>
    <div v-if="!trial" class="small text-muted mb-3">
      By clicking “Register”, you agree to our
      <BLink v-bind:to="{ name: 'legal-terms' }">terms of use</BLink> and
      <BLink v-bind:to="{ name: 'legal-privacy' }">privacy policy</BLink>.
    </div>
    <div v-if="!trial" class="text-center">
      Already have an account?
      <BLink v-bind:to="{ name: 'account-user-signin' }">
        Sign In
      </BLink>
    </div>
  </BForm>
</template>

<script>
import { email, minLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import Alert from "./Alert.vue";

export default {
  components: {
    Alert
  },
  props: {
    reference: {
      type: String,
      required: false
    },
    trial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: null,
      password: null
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading
    })
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch("auth/signup", {
          email: this.email,
          password: this.password,
          reference: this.reference
        });
        this.email = this.password = null;
        this.$v.$reset();
      }
    }
  }
};
</script>
