<template>
  <main id="home">
    <section id="about" class="bg-dark">
      <BContainer class="pt-3 pt-md-5 pb-5 pb-md-3">
        <BRow class="align-items-center">
          <BCol xl="8">
            <h1 class="text-white text-shadow display-5 mb-4">
              Increase your review site ratings by encouraging happy customers to leave reviews
            </h1>
            <p class="lead mb-0">
              Improve your ratings online by redirecting customers that love your product to a specific review site.
            </p>
          </BCol>
          <BCol>
            <AccountUserCreateForm v-if="!session" reference="widget-create" v-bind:trial="true" />
          </BCol>
        </BRow>
        <review-boostr v-bind:id="boostrId" />
      </BContainer>
      <svg
        aria-hidden="true"
        class="d-none d-md-inline-block w-100 divider"
        preserveAspectRatio="none"
        viewBox="0 0 1440 62"
      >
        <path
          fill="#0d1117"
          d="M754.4 30.8C1021.3 -19.1 1269.8 1.9 1440 22L1440 62L0 62L0 22C187.3 44.2 430 91.4 754.4 30.8Z"
        />
      </svg>
    </section>
    <BContainer id="how-it-works" tag="section">
      <BRow class="align-items-center py-3 py-md-5">
        <BCol lg="7" order-lg="2">
          <h2 class="display-6 mb-3">How It Works</h2>
          <p class="lead-2">
            The unobtrusive widget pops up for a user in the bottom-right of the website, asking to leave a rating out
            of five. If the user submits a 5/5 score, a message is displayed asking the user if they'd be willing to
            leave a review.
          </p>
        </BCol>
        <BCol order-lg="1">
          <BCarousel id="widget-carousel" style="text-shadow: 1px 1px 2px #333;" v-bind:interval="4000">
            <BCarouselSlide>
              <template v-slot:img>
                <BImg
                  alt="Leave a rating out of five stars"
                  fluid
                  height="677"
                  rounded
                  v-bind:src="clientUrl + '/img/slides/rating.png'"
                  width="1200"
                />
              </template>
            </BCarouselSlide>
            <BCarouselSlide>
              <template v-slot:img>
                <BImg
                  alt="Click the button to leave a review"
                  fluid
                  height="677"
                  rounded
                  v-bind:src="clientUrl + '/img/slides/outbound.png'"
                  width="1200"
                />
              </template>
            </BCarouselSlide>
          </BCarousel>
        </BCol>
      </BRow>
    </BContainer>
    <section id="reports" class="bg-dark">
      <BContainer class="py-3 py-md-5 bg-dark">
        <BRow class="align-items-center">
          <BCol lg="6">
            <h2 class="display-6 mb-3">Reports</h2>
            <p class="lead-2">
              View the percentage of people directed towards the review site. Track the rating breakdown, average and
              individual events.
            </p>
          </BCol>
          <BCol>
            <BImg v-bind:src="clientUrl + '/img/reports.png'" fluid alt="Event metrics" width="1032" height="612" />
          </BCol>
        </BRow>
      </BContainer>
    </section>
    <BContainer id="install" class="py-3 py-md-5" tag="section">
      <BRow class="align-items-center">
        <BCol lg="6" order-lg="2">
          <h2 class="display-6 mb-3">Install</h2>
          <p class="lead-2">
            Use with content management systems, static websites and JavaScript apps.
          </p>
          <p class="lead-2">
            If your install base isn't listed, contact us after you
            <BLink v-bind:to="{ name: 'widget-create' }">create your widget</BLink>, and we can help.
          </p>
        </BCol>
        <BCol order-lg="1">
          <BImg
            alt="Install instructions"
            fluid
            height="420"
            v-bind:src="clientUrl + '/img/install.png'"
            width="1032"
          />
        </BCol>
      </BRow>
    </BContainer>
  </main>
</template>

<script>
import { mapState } from "vuex";
import AccountUserCreateForm from "./AccountUserCreateForm.vue";

export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "ReviewBoostr helps businesses convince happy customers to leave positive reviews on a specific review site. Try for free."
      },
      {
        property: "og:title",
        content: "Boost your online review scores with ReviewBoostr"
      },
      {
        property: "og:description",
        content:
          "ReviewBoostr helps businesses convince happy customers to leave positive reviews on a specific review site. Try for free."
      }
    ],
    title: "Boost your online review scores with ReviewBoostr",
    titleTemplate: null
  }, // <script type="application/ld+json" class="yoast-schema-graph">{"@context":"https://schema.org","@graph":[{...
  components: {
    AccountUserCreateForm
  },
  data() {
    return {
      clientUrl: process.env.VUE_APP_CLIENT_URL,
      boostrId: process.env.VUE_APP_BOOSTR_ID
    };
  },
  computed: {
    ...mapState({
      session: state => state.auth.session
    })
  }
};
</script>

<style scoped>
.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .display-5 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
  }
}
.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.lead-2 {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 2;
}
.divider {
  height: 62px;
}
</style>
