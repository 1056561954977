<template>
  <BNavbar sticky tag="header" toggleable="lg" variant="dark" type="dark">
    <Alert class="position-fixed navbar-alert" />
    <BNavbarBrand v-bind:to="{ name: 'home' }">
      <Logo v-bind:size="35" />
      <span class="ml-2">
        {{ brand }}
      </span>
    </BNavbarBrand>
    <BNavbarToggle target="navCollapse" />
    <BCollapse is-nav id="navCollapse">
      <BNavbarNav>
        <BNavItem v-bind:to="{ name: 'home', hash: '#how-it-works' }" class="ml-lg-3">How It Works</BNavItem>
        <BNavItem v-bind:to="{ name: 'home', hash: '#reports' }" class="ml-lg-3">Reports</BNavItem>
        <BNavItem v-bind:to="{ name: 'home', hash: '#install' }" class="ml-lg-3">Install</BNavItem>
      </BNavbarNav>
      <BNavbarNav class="ml-auto">
        <BNavItemDropdown
          right
          v-bind:text="
            widgetActive !== null && ($route.name === 'widget' || $route.name === 'widget-edit')
              ? widgetActive.websiteName
              : 'Widgets'
          "
          v-if="widgets !== null"
        >
          <BDropdownItem
            v-bind:key="widget.id"
            v-bind:to="{ name: 'widget', params: { id: widget.id } }"
            v-for="widget in widgets"
          >
            {{ widget.websiteName }}
          </BDropdownItem>
          <BDropdownDivider />
          <BDropdownItem exact-active-class="active" v-bind:to="{ name: 'widget-create' }">
            <BIconPlus aria-hidden="true" />
            New Widget
          </BDropdownItem>
        </BNavItemDropdown>

        <div v-if="session === null" class="d-flex flex-row flex-wrap align-items-center">
          <BButton size="sm" v-bind:to="{ name: 'account-user-create', query: { reference: 'widget-create' } }">
            Get Started
          </BButton>
        </div>
        <div v-if="session !== null && !widgets" class="d-flex flex-row flex-wrap align-items-center">
          <BButton size="sm" v-bind:to="{ name: 'widget-create' }">
            Get Started
          </BButton>
        </div>

        <BNavItem class="ml-lg-3" v-if="session" v-on:click="onLogout">
          Logout
        </BNavItem>
        <BNavItem class="ml-lg-3" v-else v-bind:to="{ name: 'account-user-signin' }">
          Sign In
        </BNavItem>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Alert from "./Alert.vue";
import Logo from "./Logo.vue";

export default {
  components: {
    Alert,
    Logo
  },
  data() {
    return {
      brand: process.env.VUE_APP_CLIENT_NAME
    };
  },
  created() {
    this.$store.dispatch("user/userWidgets");
  },
  computed: {
    ...mapState({
      session: state => state.auth.session
    }),
    ...mapGetters({
      widgetActive: "widget/widgetActive",
      widgets: "widget/widgetsByUser"
    })
  },
  methods: {
    async onLogout() {
      await this.$store.dispatch("auth/signout");
      if (this.$route.path !== "/") this.$router.push({ name: "home" });
    }
  }
};
</script>
