<template>
  <footer class="bg-dark py-5">
    <BContainer fluid tag="footer" class="d-flex flex-column align-items-center justify-content-between">
      <div class="footer-brand font-weight-light">
        <BLink class="text-decoration-none text-white" v-bind:to="{ name: 'home' }">
          <Logo v-bind:to="{ name: 'home' }" v-bind:size="50" class="mr-1" />
          {{ brand }}
        </BLink>
      </div>
      <div class="my-3">
        <BNav align="center">
          <BNavItem v-bind:to="{ name: 'home', hash: '#how-it-works' }">How It Works</BNavItem>
          <BNavItem v-bind:to="{ name: 'home', hash: '#reports' }">Reports</BNavItem>
          <BNavItem v-bind:to="{ name: 'home', hash: '#install' }">Install</BNavItem>
          <BNavItem v-bind:to="{ name: 'legal-terms' }">Terms of Use</BNavItem>
          <BNavItem v-bind:to="{ name: 'legal-privacy' }">Privacy Policy</BNavItem>
        </BNav>
      </div>
      <div>
        <small class="text-muted">&copy; {{ new Date().getFullYear() }} ZergLabs</small>
      </div>
    </BContainer>
  </footer>
</template>

<script>
import Logo from "./Logo.vue";

export default {
  components: {
    Logo
  },
  data() {
    return {
      brand: process.env.VUE_APP_CLIENT_NAME
    };
  }
};
</script>

<style scoped>
footer {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.footer-brand {
  font-size: 1.5rem;
  line-height: inherit;
  white-space: nowrap;
}
</style>
